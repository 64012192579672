<template>
  <div class="authWrapper">
    <div class="container">
      <div class="row whiteBG-wrap">
        <div class="col-lg-6 col-md-6 no-padding">
          <div class="left-bg-wrapper text-center">
            <h1>Hello</h1>
            <p>Please enter otp to upload your file.</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="auth-form-wrap auth-form">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form
                @submit.stop.prevent="handleSubmit(uploadFile)"
                ref="addFileContainer"
              >
                <div class="row">
                  <input-text
                    :rules="{ required: true }"
                    labelFor="uploaded_by"
                    labelName="Name"
                    inputName="name"
                    :vmodel.sync="form.uploaded_by"
                    formGroupClass="form-group col-lg-6 col-md-6 mb-3"
                    :api-errors="apiErrors"
                  />

                  <input-text
                    :rules="{ required: true }"
                    labelFor="file_title"
                    labelName="File Title"
                    inputName="file_title"
                    :vmodel.sync="form.file_title"
                    formGroupClass="form-group col-lg-6 col-md-6 mb-3"
                    :api-errors="apiErrors"
                  />
                </div>
                <div class="row">
                  <input-multi-select
                    labelFor="doc_type"
                    :vmodel.sync="form.doc_type"
                    labelName="FileType"
                    :options="fileTypes.map((type) => type.value)"
                    :custom-label="
                      (opt) => fileTypes.find((x) => x.value == opt).text
                    "
                    :rules="{ required: true }"
                    formGroupClass="form-group col-lg-12 col-md-12 mb-3"
                    placeholder="Select File Type"
                    :api-errors="apiErrors"
                  />
                </div>

                <input-file
                  :rules="{ required: true, mimes: mimeTypes }"
                  labelFor="file"
                  labelName="Select File (txt, png, jpg, jpeg, xlsx, pdf, csv, doc and pptx) "
                  inputName="select_file"
                  :vmodel.sync="file"
                  :acceptMimeTypes="mimeTypes"
                  :api-errors="apiErrors"
                  formGroupClass="form-group mb-3"
                />
                <input-textarea
                  :rules="{ required: true }"
                  labelFor="file"
                  labelName="File Notes "
                  inputName="file"
                  :vmodel.sync="form.doc_notes"
                  formGroupClass="form-group mb-3"
                  :api-errors="apiErrors"
                />

                <div class="auth-form upload-file-share-btn">
                  <h5>Enter OTP</h5>
                  <v-otp-input
                    ref="otpInput"
                    input-classes="otp-input"
                    separator=""
                    :num-inputs="4"
                    :should-auto-focus="true"
                    :is-input-num="true"
                    @on-change="handleOnChange"
                    @on-complete="handleOnComplete"
                  />
                  <div class="otp-error">
                    <error-text
                      v-if="apiErrors"
                      :errors="apiErrors"
                      field="otp"
                    />
                  </div>
                </div>
                <div class="col-md-12 upload-file-action">
                  <button
                    type="submit"
                    :class="isOtpComplete ? 'submit-btn' : 'disable-submit-btn'"
                    :disabled="isUpload || !isOtpComplete"
                  >
                    {{ isUpload ? "Uploading...." : "Upload" }}
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";

let axiosInstance = axios.create({ headers: "" });
axiosInstance.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;
const formData = {
  file_title: "",
  doc_notes: "",
  doc_type: null,
  otp: "",
  platform_user_id: "",
  platform_user_type: "",
  uploaded_by: "",
};
export default {
  name: "UploadFileShare",
  data: () => ({
    isOtpComplete: false,
    isUpload: false,
    form: {
      ...formData,
    },
    file: null,
    fileTypes: [
      { value: "My Files", text: "My Files" },
      { value: "Forms", text: "Forms" },
      { value: "Enrollment Form", text: "Enrollment Form" },
      { value: "Census File", text: "Census File" },
      { value: "Member Docs", text: "Member Docs" },
      { value: "Rep Doc", text: "Rep Doc" },
      { value: "Commission Statement", text: "Commission Statement" },
    ],
    apiErrors: null,
    isCreate: false,
    mimeTypes: [
      "application/doc",
      "application/ms-doc",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-powerpoint",
      "application/mspowerpoint",
      "application/powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/excel",
      "application/x-msexcel",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/plain",
      "application/pdf",
      "text/csv",
      "image/jpeg",
      "image/jpg",
      "image/png",
    ],
  }),
  computed: {
    ...mapGetters({ rep: "rep" }),
  },
  methods: {
    setData() {
      this.form = {
        file_title: "",
        doc_notes: "",
        doc_type: null,
        otp: "",
        platform_user_id: this.rep.id,
        platform_user_type: "REP",
        uploaded_by: "",
      };
       if(this.$route.query.name){
      this.form.uploaded_by = this.$route.query.name
      this.$nextTick(() => {
        this.$router.replace({'query': null});
      })
    }
    },
    async uploadFile() {
      const url = `api.access/v2/file-share/upload`;
      let formData = new FormData();
      formData.append("file", this.file);
      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          formData.append(key, value);
        }
      }
      this.isUpload = true;
      let loader = this.$loading.show();
      await axiosInstance
        .post(url, formData)
        .then((res) => {
          this.handleClearInput();
          this.$notify(res.data.message);
        })
        .catch((err) => {
          if (err.response.status == 422) {
            this.apiErrors = err.response.data.errors;
            setTimeout(() => {
              this.apiErrors = null;
            }, 5000);
          } else {
            this.$notify(err.response.data.message, "error");
          }
        })
        .finally(() => {
          this.isUpload = false;
          loader.hide();
        });
    },
    handleOnComplete(value) {
      this.isOtpComplete = true;
      this.form.otp = value;
    },
    handleOnChange(value) {
      this.form.otp = value;
    },
    handleClearInput() {
      this.form = { ...formData };
      this.$refs.otpInput.clearInput();
      this.$refs.observer.reset();
    },
  },
  created() {
    this.setData();
  },
};
</script>
<style src="@/assets/css/authen.css"></style>
<style>
.highlight_otp {
  border: 2px solid red !important;
}

button.disable-submit-btn {
  width: 100%;
  border: 0;
  background: rgb(172, 202, 209);
  color: #fff;
  padding: 13px;
  font-weight: 600;
  border-radius: 6px;
  outline: none;
  transition: all ease 0.6s;
}

.otp-error {
  padding-left: 12px;
}

.upload-file-share-btn {
  padding: 0;
}

.upload-file-action {
  margin-top: 20px;
}
</style>
