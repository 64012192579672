var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "authWrapper"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row whiteBG-wrap"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-6 col-md-6"
  }, [_c('div', {
    staticClass: "auth-form-wrap auth-form"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          ref: "addFileContainer",
          on: {
            "submit": function submit($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return handleSubmit(_vm.uploadFile);
            }
          }
        }, [_c('div', {
          staticClass: "row"
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "uploaded_by",
            "labelName": "Name",
            "inputName": "name",
            "vmodel": _vm.form.uploaded_by,
            "formGroupClass": "form-group col-lg-6 col-md-6 mb-3",
            "api-errors": _vm.apiErrors
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "uploaded_by", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "file_title",
            "labelName": "File Title",
            "inputName": "file_title",
            "vmodel": _vm.form.file_title,
            "formGroupClass": "form-group col-lg-6 col-md-6 mb-3",
            "api-errors": _vm.apiErrors
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "file_title", $event);
            }
          }
        })], 1), _c('div', {
          staticClass: "row"
        }, [_c('input-multi-select', {
          attrs: {
            "labelFor": "doc_type",
            "vmodel": _vm.form.doc_type,
            "labelName": "FileType",
            "options": _vm.fileTypes.map(function (type) {
              return type.value;
            }),
            "custom-label": function customLabel(opt) {
              return _vm.fileTypes.find(function (x) {
                return x.value == opt;
              }).text;
            },
            "rules": {
              required: true
            },
            "formGroupClass": "form-group col-lg-12 col-md-12 mb-3",
            "placeholder": "Select File Type",
            "api-errors": _vm.apiErrors
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "doc_type", $event);
            }
          }
        })], 1), _c('input-file', {
          attrs: {
            "rules": {
              required: true,
              mimes: _vm.mimeTypes
            },
            "labelFor": "file",
            "labelName": "Select File (txt, png, jpg, jpeg, xlsx, pdf, csv, doc and pptx) ",
            "inputName": "select_file",
            "vmodel": _vm.file,
            "acceptMimeTypes": _vm.mimeTypes,
            "api-errors": _vm.apiErrors,
            "formGroupClass": "form-group mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              _vm.file = $event;
            }
          }
        }), _c('input-textarea', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "file",
            "labelName": "File Notes ",
            "inputName": "file",
            "vmodel": _vm.form.doc_notes,
            "formGroupClass": "form-group mb-3",
            "api-errors": _vm.apiErrors
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "doc_notes", $event);
            }
          }
        }), _c('div', {
          staticClass: "auth-form upload-file-share-btn"
        }, [_c('h5', [_vm._v("Enter OTP")]), _c('v-otp-input', {
          ref: "otpInput",
          attrs: {
            "input-classes": "otp-input",
            "separator": "",
            "num-inputs": 4,
            "should-auto-focus": true,
            "is-input-num": true
          },
          on: {
            "on-change": _vm.handleOnChange,
            "on-complete": _vm.handleOnComplete
          }
        }), _c('div', {
          staticClass: "otp-error"
        }, [_vm.apiErrors ? _c('error-text', {
          attrs: {
            "errors": _vm.apiErrors,
            "field": "otp"
          }
        }) : _vm._e()], 1)], 1), _c('div', {
          staticClass: "col-md-12 upload-file-action"
        }, [_c('button', {
          class: _vm.isOtpComplete ? 'submit-btn' : 'disable-submit-btn',
          attrs: {
            "type": "submit",
            "disabled": _vm.isUpload || !_vm.isOtpComplete
          }
        }, [_vm._v(" " + _vm._s(_vm.isUpload ? "Uploading...." : "Upload") + " ")])])], 1)];
      }
    }])
  })], 1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-6 col-md-6 no-padding"
  }, [_c('div', {
    staticClass: "left-bg-wrapper text-center"
  }, [_c('h1', [_vm._v("Hello")]), _c('p', [_vm._v("Please enter otp to upload your file.")])])]);
}]

export { render, staticRenderFns }